import React, {FunctionComponent} from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FavoriteIcon from '@mui/icons-material/Favorite';

interface ApproveDialogProps {
    setOpen:any
    open:boolean
}

const ApproveDialog:FunctionComponent<ApproveDialogProps> = (props) => {

    return (
            <div>
                <Dialog
                    open={props.open}
                    onClose={()=> {props.setOpen(false)}}
                >
                    <DialogTitle>
                        Thank you for your order !
                    </DialogTitle>
                    <DialogContent style={{display: "flex", flexDirection: "column"}}>
                        <DialogContentText>
                            We have received your order,
                            you will receive your license on your payment email in a delay of 24h !
                        </DialogContentText>
                        <DialogContentText>
                            If you have any problem, contact us with : contact@jeonware.com
                        </DialogContentText>
                        <FavoriteIcon style={{marginTop: '20px'}} color={'error'} fontSize={'large'}/>
                    </DialogContent>
                    <DialogActions>
                        <Button color={'success'} onClick={()=> {props.setOpen(false)}}>
                            Ok !
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
    )
}

export default ApproveDialog