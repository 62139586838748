import React, {FunctionComponent, useRef} from "react";
import styles from "./MenuBar.module.css"
import JeLogo from "../../assets/Logo/LogoAlpha.png";
import {AppBar, Button, Popover, Toolbar, Typography, MenuItem, Menu} from "@mui/material";
import {Box} from "@mui/system";
import {useHistory} from "react-router-dom";

interface MenuBarProps {
}

const MenuBar: FunctionComponent<MenuBarProps> = (props) => {

    const [current, setCurrent] = React.useState<string>(window.location.pathname.slice(1))
    const history = useHistory();
    const btnList = ['jecontrol', 'vrac', 'Webapp'];
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const btnRef = useRef(null)

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" className={styles.AppBar}>
            <Toolbar className={styles.ToolBar}>
                <Box flexGrow={1}>
                    <div className={styles.Logo} onClick={() => {
                        history.push('/')
                        setCurrent('')
                    }}>
                        <img decoding={"sync"} width={"55px"} src={JeLogo} alt={"JeonWare"}/>
                    </div>
                </Box>
                {
                    btnList.map((value, index) => {
                        if (value !== 'Webapp') {
                            return <Button key={index} color={value === current ? 'secondary' : 'inherit'}
                                           onClick={() => {
                                               history.push('/' + value)
                                               setCurrent(value)
                                           }}>{value}
                            </Button>
                        } else {
                            return <div >
                                <Button color={value === current ? 'secondary' : 'inherit'}
                                        onClick={(event) => {
                                            setAnchorEl(event.currentTarget)
                                        }}
                                >
                                    WebApp
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={()=> {
                                    window.open('http://trpa.jeonware.com')
                                    }
                                    }>
                                        Tricaster remote proc-amp
                                    </MenuItem>
                                </Menu>
                            </div>
                        }
                    })
                }
                <div style={{flexGrow: 1, display: "flex", justifyContent: "flex-end"}}>
                    <Button color={'contact' === current ? 'secondary' : 'inherit'}
                            onClick={() => {
                                history.push('/contact')
                                setCurrent('contact')
                            }}>Contact
                    </Button>
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default MenuBar