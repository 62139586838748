import React, {useEffect} from "react";
import MenuBar from "../../components/MenuBar/MenuBar";
import LogoBig from "../../assets/Logo/LogoBigSmallRez.png"
import JecontrolLogo from "../../assets/jecontrol/JecontrolLogo.png"
import ColorPanel from "../../assets/jecontrol/colorPanelBlur.png"
import VracUI from "../../assets/vrac/VracUIBlur_2.png"
import VracLogo from "../../assets/vrac/VracLogo.png"
import NodecgTwitter from "../../assets/nodecg/TwitterBlur.png"
import NodecgSynthe from "../../assets/nodecg/SyntheBlur.png"
import HomeBackground from "../../assets/homeBackground.webm"
import styles from "./home.module.css"
import {Box} from "@mui/material";
import InnerImage from "../../components/InnerImage/InnerImage";
import BottomBar from "../../components/BottomBar/BottomBar";
import Aos from "aos";


interface ContactProps {

}

const Home: React.FunctionComponent<ContactProps> = (props) => {

    useEffect(() => {
        Aos.init({duration: 1000});
    }, [])

    return (
        <div>
            <Box className={styles.Body}>
                <div className={styles.Header}>
                    <video data-aos="fade" data-aos-delay="5000" height={700} className={styles.Logo__Background}
                           autoPlay={true} loop={true} muted={true}>
                        <source src={HomeBackground} type={"video/webm"}/>
                    </video>
                    <div className={styles.Logo}>
                        <img src={LogoBig} width={"100%"} alt={'JeonWare'}/>
                    </div>
                </div>
                <div className={"is-size-4-mobile is-size-1-desktop " +
                "has-text-centered"} style={{backgroundColor: "#212121"}}>DISCOVER OUR SOFTWARE
                </div>
                <Box>
                    <InnerImage logo={JecontrolLogo} path={'/jecontrol'} text={"JeControl."} fontSize={"5.5rem"}
                                textSx={"is-size-1-mobile has-text-weight-bold"} src={ColorPanel} alt={'ColorPanel'}/>
                    <InnerImage logo={VracLogo} path={'/vrac'} text={"Vrac"} textSx={"is-size-1-mobile has-text-weight-bold"}
                                src={VracUI} fontSize={"5.5rem"}
                                alt={'Vrac'}/>
                    <div className={"is-size-4-mobile is-size-1-desktop " +
                    "has-text-centered"} style={{backgroundColor: "#212121"}}>NODECG GRAPHICS
                    </div>
                    <InnerImage path={''} text={"Personalised Graphics"} textSx={"is-size-1-mobile has-text-weight-bold"}
                                src={NodecgSynthe} fontSize={"5.5rem"}
                                alt={'Synthe personalised'}/>
                    <InnerImage path={''} text={"Twitter Integration"} textSx={"is-size-1-mobile has-text-weight-bold"}
                                src={NodecgTwitter} fontSize={"5.5rem"}
                                alt={'Twitter Intégration'}/>
                </Box>
            </Box>
            <BottomBar/>
        </div>
    )
}

export default Home