import React, {useEffect} from "react";
import serverPanel from "../../assets/jecontrol/server2.jpg"
import colorPanel from "../../assets/jecontrol/colorPanel.jpg"
import MenuBar from "../../components/MenuBar/MenuBar";
import colorPanelVideo from "../../assets/jecontrol/colorPanelVideo.webm"
import colorPanelFeatures from "../../assets/jecontrol/colorPanelFeatures2.webm"
import styles from "./jecontrol.module.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Aos from "aos";
import LicenseModel from "./LicenseModel/licensemodel";
import "aos/dist/aos.css";
import BottomBar from "../../components/BottomBar/BottomBar";

interface JeControlAppProps {

}

const JeControlApp: React.FunctionComponent<JeControlAppProps> = (props) => {
    useEffect(() => {
        Aos.init({duration: 1000});
    }, [])

    return (
        <div>
            <div className={`${styles.Header} has-text-centered`}>
                <div className={"columns is-gapless is-centered"} style={{padding: "7.5rem 0 4.5rem 0"}}>
                    <div data-aos="fade-left"
                         data-aos-once="true"
                         className={"column is-narrow has-text-centered"}>
                        <img width={400} src={serverPanel} alt='img'/>
                    </div>
                    <div data-aos="fade-right"
                         data-aos-once="true"
                         className={"column ml-5 mr-5 " +
                         "is-flex is-flex-direction-column has-text-centered is-align-items-center " +
                         "is-narrow"}>
                        <div className={"has-text-weight-bold"} style={{fontSize: "4.2rem"}}>JeControl.</div>
                        <div className={"is-size-5 is-italic has-text-weight-semibold"}
                             style={{paddingBottom: "5rem"}}
                        >Advanced web controller for vmix
                        </div>
                        <div
                            style={{width: "300px"}}
                            data-aos={'fade'}
                            data-aos-once="true"
                            data-aos-delay={'900'}>
                            This software is intended to be a toolbox for Vmix,
                            allowing you to manage vmix features remotely and adding new features in order to
                            fully utilize the power of the software!
                        </div>
                    </div>
                </div>
                <div className={"is-size-3 pb-4"} style={{color: "#ff9800"}}>
                    FEATURES
                </div>
            </div>
            <div className={"is-size-2 is-size-4-mobile has-text-centered"} style={{backgroundColor: "#c66900"}}>
                COLOR PANEL
            </div>
            <video className={""} autoPlay={true} loop={true} muted={true}>
                <source src={colorPanelVideo} type={"video/webm"}/>
            </video>
            <div className={"is-size-3 is-size-6-mobile has-text-centered columns p-5"}
                 style={{backgroundColor: "#1B1D22"}}>
                <div className={"column " +
                "is-flex is-flex-direction-column is-justify-content-space-around p-4"}>
                    <div
                        data-aos={'fade'}
                        className={"mb-2"}>
                        Thanks to the colorimetry panel, you can remotely control the Color Correction of vmix,
                        all features have been retransmitted in this web application format,
                        so you can offset the colorimetry for your digital imaging technician chief during your events!
                    </div>
                    <div
                        data-aos={'fade'}
                        data-aos-delay={200}>
                        The Color Panel also adds new features to make life easier for DIT,
                        adding 'cc off', 'output link' and color sliders.
                        The Color Panel makes JeControl a real professional tool to create the perfect colorimetry for your production.
                    </div>
                </div>
                <video className={"column is-6"} autoPlay={true} loop={true} muted={true}>
                    <source src={colorPanelFeatures} type={"video/webm"}/>
                </video>
            </div>
            <div className={"is-size-2 is-size-4-mobile has-text-centered"} style={{backgroundColor: "#c66900"}}>
                & MORE IN COMING, STAY TURNED !
            </div>
            <div className={""}>
                <div className={"is-size-3 is-size-4-mobile has-text-centered m-3"}>
                    EASY TO INSTALL
                </div>
                <div className={"columns has-text-centered p-6"}>
                    <div className={"column is-flex is-flex-direction-column "}>
                        <div className={"is-size-1 has-text-weight-bold"}>1</div>
                        <a href="files/jecontrol-1.0.4-win64.zip" className={"is-size-5 has-text-warning-dark"} download>
                            Download JeControl
                        </a>
                    </div>
                    <div className={"column is-flex is-justify-content-center"}>
                        <ArrowForwardIosIcon className={`${styles.Arrow}`} fontSize={"large"}/>
                    </div>
                    <div className={"column"}>
                        <div className={"is-size-1 is-flex is-flex-direction-column has-text-weight-bold"}>2</div>
                        <div className={"is-size-5"}>
                            Install and launch JeControl on the pc running vmix
                        </div>
                    </div>
                    <div className={"column is-flex is-justify-content-center"}>
                        <ArrowForwardIosIcon className={`${styles.Arrow}`} fontSize={"large"}/>
                    </div>
                    <div className={"column"}>
                        <div className={"is-size-1 is-flex is-flex-direction-column has-text-weight-bold"}>3</div>
                        <div className={"is-size-5"}>
                            Enter the ip displayed on the interface of jeControl
                            on any pc on your local network and enjoy ! :)
                        </div>
                    </div>
                </div>
                <div className={"is-size-3 is-size-4-mobile has-text-centered"}>CHOOSE YOUR LICENSE</div>
                <div className={"columns"} style={{margin: "3em 0 0 0"}}>
                    <LicenseModel/>
                    <LicenseModel premium/>
                </div>
                <nav className="breadcrumb is-centered mb-5 has-bullet-separator" aria-label="breadcrumbs">
                    <ul>
                        <li><a className={"has-text-warning-dark"} href="files/jecontrol-1.0.4-win64.zip">Download</a></li>
                        <li><a className={"has-text-warning-dark"}  href="files/jecontrol-changelog.txt">Changelog</a></li>
                    </ul>
                </nav>
            </div>
            <BottomBar/>
        </div>
    )
}

export default JeControlApp