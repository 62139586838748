import React, {useEffect} from "react";
import VracLauncher from "../../assets/vrac/VracLauncher.png";
import VracUI from "../../assets/vrac/VracUI.png";
import VracLogo from "../../assets/vrac/VracLogo.png"
import MenuBar from "../../components/MenuBar/MenuBar";
import styles from "./vrac.module.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BottomBar from "../../components/BottomBar/BottomBar";
import {Box} from "@mui/system";
import Aos from "aos";
import {FUNDING, PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";

interface JeControlAppProps {

}

const Vrac: React.FunctionComponent<JeControlAppProps> = (props) => {

    useEffect(() => {
        Aos.init({duration: 1000});
    }, [])

    return (
        <div>
            <div className={`${styles.Header} pt-6`}>
                <div className={"is-flex is-justify-content-center is-align-items-center p-2"} data-aos={"fade"}>
                    <div className={"pt-6 mr-3"}>
                        <img width={180} src={VracLogo} className={""}/>
                    </div>
                    <div className={""}>
                        <div className={"has-text-weight-bold"} style={{fontSize: "5.5em"}}>
                            Vrac
                        </div>
                        <div className={"is-italic is-size-4"}>
                            Vmix Remote Audio Controller
                        </div>
                    </div>
                </div>
                <div className={"columns is-centered mt-6"} data-aos={"fade-down"}>
                    <div className={"column is-one-fifth has-text-centered is-size-5"}
                         style={{paddingBottom: "10rem"}}>
                        Vrac is a tool to destined to control remotely the audio of a Vmix session,
                        you can get a PGM return, control the rooting, gain, volume, bus !
                        Vrac is useful when you want to let you audio engineer control the sound of your Vmix
                        session remotely.
                    </div>
                </div>
            </div>
            <div className={styles.Setup}>
                <div className={"is-size-2 has-text-centered has-text-weight-light p-1"}
                     style={{backgroundColor: "#212121"}}>SETTING UP
                </div>
                <div className={"columns"}>
                    <div
                        className={"column is-size-4 has-text-centered m-2 " +
                        "is-flex is-flex-direction-column " +
                        "is-justify-content-center is-align-items-center"}>
                        <a className={"mb-5 has-text-warning-dark"} href="https://bit.ly/34Jyhig" download>
                            Download Vrac
                        </a>
                        <ArrowForwardIosIcon className={"mb-5"} fontSize={"large"}
                                             sx={{transform: "rotate(90deg)"}}/>
                        <div className={"mb-5"}>Launch it and type the ip address of the remote Vmix</div>
                        <ArrowForwardIosIcon className={"mb-5"} fontSize={"large"}
                                             sx={{transform: "rotate(90deg)"}}/>
                        <div className={"mb-5"}>
                            Click on "launch UI" and that's it, you can also click on "GetAllInput" to get All
                            inputs of
                            the remote Vmix directly !
                        </div>
                    </div>
                    <img style={{objectFit: "contain"}} className={"column is-three-quarters"} src={VracUI}/>
                </div>
            </div>
            <BottomBar/>
        </div>
    )
}

export default Vrac