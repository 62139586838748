import React, {useCallback} from 'react';
import './App.css';
import {AppBar, Button, Toolbar, Typography} from '@mui/material';
import {Route, Switch, BrowserRouter, useHistory} from "react-router-dom";
import {Box} from '@mui/system';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Contact from './pages/Contact/contact'
import Vrac from './pages/Vrac/vrac'
import Home from './pages/Home/home'
import JeControlApp from './pages/JeControl/jecontrol';
import {createTheme, ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import {dark} from "@mui/material/styles/createPalette";
import "./mystyles.css"
import MenuBar from "./components/MenuBar/MenuBar";


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            light: '#484848',
            main: '#212121',
            dark: '#000000',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#ff5131',
            main: '#d50000',
            dark: '#9b0000',
            contrastText: '#ffffff',
        }
    },
});

function App() {

    return (
        <ThemeProvider theme={darkTheme}>
            <div className={"content has-text-white"}>
                <MenuBar/>
                <Switch>
                    <Route exact path="/"><Home/></Route>
                    <Route exact path="/contact"><Contact/></Route>
                    <Route exact path="/jecontrol"><JeControlApp/></Route>
                    <Route exact path="/vrac"><Vrac/></Route>
                </Switch>
            </div>
        </ThemeProvider>
    );
}

export default App;
