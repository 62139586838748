import React, {useState} from "react";
import styles from "./licensemodel.module.css";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import {PayPalScriptProvider, PayPalButtons, FUNDING} from "@paypal/react-paypal-js";
import ApproveDialog from "../ApproveDialog/ApproveDialog";


interface LicenseModelProps {
    premium?: boolean
}

const LicenseModel: React.FunctionComponent<LicenseModelProps> = (props) => {

    const [open, setOpen] = useState<boolean>(false)

    return (
        <div className={`${styles.LicenseModel__Type} column is-offset-2 mb-5 mt-5 is-3`}>
            <div className={styles.LicenseModel__Subheader}>{props.premium ? "Premium" : "Free"}</div>
            {['Color wheel', 'CC-off', 'Color-slider', 'Input-link', 'Luminance', 'Hue', 'Saturation'].map((value, index) => {
                return (<div className={styles.LicenseModel__FeatureContainer}>
                    <div className={styles.LicenseModel__Feature}>{value}
                    </div>
                    {['Input-link', 'Luminance', 'Hue', 'Saturation'].includes(value) ? props.premium ?
                            <CheckCircleRoundedIcon color={'success'} className={styles.LicenseModel__Valid}/> : "" :
                        <CheckCircleRoundedIcon className={styles.LicenseModel__Valid}/>}
                </div>)
            })}
            <div>
                <div className={"has-text-centered is-size-5 has-text-weight-bold m-5"}>
                    {props.premium ? "Premium license - 199,99€" : "0€"}
                </div>

                {props.premium ? <PayPalScriptProvider
                    options={{
                        "client-id": "Aelc45ezbEyBIekO4k3MyRFf8OWqKN6f6jSTK0Jxhg7tV1Ke5OiMRLjlut4L-8Ek7bpON8kIl-z63r2v",
                        "currency": "EUR"
                    }}>
                    <PayPalButtons createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: "199.99",
                                    }
                                }
                            ]
                        })
                    }
                    } fundingSource={FUNDING.PAYPAL} onApprove={(data, actions) => {
                        return actions.order.capture().then(() => {
                            setOpen(true)
                        })
                    }
                    }/>
                    <div className={"is-size-6 has-text-centered is-italic"}>
                    <div>License can be used on one computer at a time, but can be move between computers</div>
                        </div>
                </PayPalScriptProvider> :
                    <div className={"is-size-5 has-text-centered is-italic"}>Already in the software</div>}
                    <ApproveDialog setOpen={setOpen} open={open}/>
                    </div>
                    </div>
                    )
                }

                export default LicenseModel