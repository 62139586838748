import React, {FunctionComponent, useEffect, useState} from "react";
import styles from "./InnerImage.module.css"
import Aos from "aos";
import {useHistory} from "react-router-dom";


interface InnerImageProps {
    src: string
    logo?: string
    alt: string
    text: string
    textSx: any
    path: string
    fontSize?: string
}


const InnerImage: FunctionComponent<InnerImageProps> = (props) => {

    const history = useHistory();

    useEffect(() => {
        Aos.init({duration: 600});
    }, [])

    return (
        <div>
            <div className={styles.item__imgWarp}
                 onClick={() => {
                     if (props.path !== '') {
                         window.scrollTo(0, 0);
                         history.push(props.path)
                     }
                 }}>
                <div className={`${styles.item__img} ${props.textSx}`}
                     style={{
                         fontSize: props.fontSize,
                         backgroundImage: `url(${props.src})`,
                         cursor: props.path === '' ? 'default' : 'pointer'
                     }}>
                    {
                        props.logo?
                        <img className={styles.item__logo} data-aos="fade" src={props.logo}/> :
                        null
                    }

                    <div className={styles.item__txt} data-aos="fade">
                        {props.text}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InnerImage