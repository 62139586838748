import React, {FunctionComponent} from "react";
import styles from "./BottomBar.module.css"
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link'
import {AppBar} from "@mui/material";


interface BottomMenuProps {
    sx?: any
}

const BottomMenu: FunctionComponent<BottomMenuProps> = (props) => {
    return (
        <div
            className={`${props.sx} is-flex is-justify-content-center 
            is-flex-direction-column is-align-items-center p-2`}
            style={{backgroundColor: "#212121"}}>
            <div className={"mb-2"}>
                Jeonware 2022 | contact@jeonware.com
            </div>
            <div className={"breadcrumb is-flex"}>
                {['home', 'jecontrol', 'vrac', 'contact'].map((value) => {
                    return <li><a className={"has-text-danger-dark"} href={'/' + (value === 'home' ? '' : value)} color={'inherit'}>{value}</a></li>
                })}
            </div>
        </div>
    )
}

export default BottomMenu