import React, {useState} from "react";
import MenuBar from "../../components/MenuBar/MenuBar";
import BottomBar from "../../components/BottomBar/BottomBar";
import styles from "./contact.module.css"
import TextField from '@mui/material/TextField';
import {Button} from "@mui/material";


interface ContactProps {

}

const Contact: React.FunctionComponent<ContactProps> = (props) => {

    const [first, setFirst] = useState<string>('')
    const [firstError, setFirstError] = useState<boolean>(false)
    const [last, setLast] = useState<string>('')
    const [lastError, setLastError] = useState<boolean>(false)
    const [subject, setSubject] = useState<string>('')
    const [subjectError, setSubjectError] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [messageError, setMessageError] = useState<boolean>(false)

    const sendMail = () => {
        setFirstError(first === '')
        setLastError(last === '')
        setSubjectError(subject === '')
        setMessageError(message === '')
        if(!(first === '') && !(last === '') && !(subject=== '') && !(message==='')) {
            window.open(`mailto:contact@jeonware.com?subject=${subject}&body=${message}%0A${first}%20${last}`)
        }
    }

    return (
        <div>
            <div className={styles.body}>
                <div className={styles.header}>
                    A question ? You have idea to add to our software ? You currently have a issue ?
                </div>
                <div className={styles.NameContainer}>
                    <TextField label={'First'} color={'info'} className={styles.NameContainer__First}
                               onChange={(object) => {
                                   setFirst(encodeURI(object.target.value))
                                   setFirstError(false)
                               }}
                               error={firstError}
                               required>
                    </TextField>
                    <TextField label={'Last'} color={'info'} className={styles.NameContainer__Last}
                               onChange={(object) => {
                                   setLast(encodeURI(object.target.value))
                                   setLastError(false)
                               }}
                               error={lastError}
                               required>
                    </TextField>
                </div>
                <TextField label={'Subject'} color={'info'} className={styles.Email}
                           onChange={(object) => {
                               setSubject(encodeURIComponent(object.target.value))
                               setSubjectError(false)
                           }}
                           error={subjectError}
                           required>
                </TextField>
                <TextField className={styles.Description} label={'Your message'} color={'info'} rows={'7'} multiline
                           onChange={(object) => {
                               setMessage(encodeURIComponent(object.target.value))
                               setMessageError(false)
                           }}
                           error={messageError}
                           required>
                </TextField>
                <Button onClick={sendMail} className={styles.SendBtn} variant={'contained'} color={'info'}>Send</Button>
            </div>
            <div className={styles.BottomBar}>
                <BottomBar/>
            </div>
        </div>
    )
}

export default Contact